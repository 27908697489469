import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import produce from 'immer';
import { withRouter } from 'react-router-dom';
import {
  Grid,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  Paper,
  Typography,
  InputLabel,
  Input,
  Button,
  Select,
  MenuItem,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import _ from 'lodash';
import { enqueueError } from '../../modules/global';

import SectionHeader from '../../components/typo/SectionHeader';
import SearchResult from './SearchResult';
import DateSearchTypeOption from './DateSearchTypeOption';
import PersonSearchOption from './PersonSearchOption';
import CompanySearchOption from './CompanySearchOption';
import VehicleSearchOption from './VehicleSearchOption';
import ItemSearchOption from './ItemSearchOption';
import LookupMultipleSearchOption from './LookupMultipleSearchOption';
import AttachmentSearchOption from './AttachmentSearchOption';
import AddressSearchOption from './AddressSearchOption';
import BaseSearchCriteria from './BaseSearchCriteria';
import DefaultLayout from '../DefaultLayout';
import { IncidentDef, LookupDataSetDef } from '../../components/propTypeDefs';
import {
  searchIncidents,
  resetSearch,
  saveCustom,
  openCustom,
  closeCustom,
} from './ducks';
import {
  IncidentSearchType,
  LookupTypeKey,
  DateSearchPeriodType,
  DefaultDateFormat,
  getDefaultSearchResultColumns,
  CustomReportTypeEnum,
} from '../../constants';
import { getInitData } from '../../modules/common';
import LookupMultipleSearchCriteria from './LookupMultipleSearchCriteria';
import { getPersonName } from '../../utils/nameUtil';
import { getDescendantLookupIds } from '../../utils/lookupTreeUtil';
import SaveCustomSearchDialog from './SaveCustomSearchDialog';
import StatusSearchOption from './StatusSearchOption';
import { translateConfig } from '../../utils/simpleTranslateUtil';
import CreatorSearchOption from './CreatorSearchOption';
import { useMediaInfo } from '../../utils/hooks';
import AlertDialog from '../../components/dialogs/AlertDialog';
import HelpButton from '../../components/buttons/HelpButton';
import LookupSingleSearchOption from './LookupSingleSearchOption';
import { getSingleLookupName } from '../../utils/incidentViewUtil';

const useStyles = makeStyles(theme => ({
  grid: {
    // maxWidth: 1200,
  },
  searchType: {
    width: '100%',
    // display: 'flex',
    // flexDirection: 'row',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    // width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexGrow: 1,
    },
  },
  formControlShort: {
    margin: theme.spacing(1),
    width: 150,
    // width: '100%',
  },
  formControlLong: {
    margin: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  formControlFull: {
    margin: theme.spacing(1),
    width: '100%',
  },
  group: {
    margin: `${theme.spacing(1)}px 0`,
    flexDirection: 'row',
  },
  paper: {
    // marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(3),
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  searchFields: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  searchButton: {
    width: 200,
    marginTop: 10,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flexGrow: 1,
    },
  },
  searchCriteria: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  searchCriteriaPaper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  searchCriteriaTitle: {
    display: 'flex',
    // flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  labelWithHelpIcon: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const getMonthLabel = months => {
  const monthNames = moment.monthsShort();
  let result = '';
  months.forEach(month => {
    result = `${result + monthNames[month - 1]} `;
  });

  return result.trim();
};

const getWeekDaysLabel = dayOfWeeks => {
  const weekdaysNames = moment.weekdaysShort();
  let result = '';
  dayOfWeeks.forEach(day => {
    result = `${result + weekdaysNames[day - 1]} `;
  });

  return result.trim();
};

const getDateCriteriaName = accountDateFormat => option => {
  switch (option.periodType) {
    case DateSearchPeriodType.period:
      return `From  ${moment(option.fromDate).format(
        DefaultDateFormat(accountDateFormat).dateMoment,
      )}  To  ${moment(option.toDate).format(
        DefaultDateFormat(accountDateFormat).dateMoment,
      )}`;
    case DateSearchPeriodType.month:
      return getMonthLabel(option.months);
    case DateSearchPeriodType.dayOfWeek:
      return getWeekDaysLabel(option.dayOfWeeks);
    case DateSearchPeriodType.lastNDays:
      if (option.days === 1) {
        return `Last 1 day`;
      }
      return `Last ${option.days} days`;
    case DateSearchPeriodType.lastNMonths:
      if (option.lastNMonths === 0) {
        return `Current month`;
      }
      if (option.lastNMonths === 1) {
        return `Prvious month`;
      }
      return `Last ${option.lastNMonths} months`;
    default:
      return '';
  }
};

const Search = props => {
  const classes = useStyles();
  const defaultCriteria = {
    [IncidentSearchType.dateReported]: { values: [], logicalOperation: 'OR' },
    [IncidentSearchType.dateOccurred]: { values: [], logicalOperation: 'OR' },
    [IncidentSearchType.class]: { values: [], logicalOperation: 'OR' },
    [IncidentSearchType.severity]: { values: [], logicalOperation: 'OR' },
    [IncidentSearchType.person]: { values: [], logicalOperation: 'OR' },
    [IncidentSearchType.company]: { values: [], logicalOperation: 'OR' },
    [IncidentSearchType.item]: { values: [], logicalOperation: 'OR' },
    [IncidentSearchType.vehicle]: { values: [], logicalOperation: 'OR' },
    [IncidentSearchType.location]: { values: [], logicalOperation: 'OR' },
    [IncidentSearchType.address]: { values: [], logicalOperation: 'OR' },
    [IncidentSearchType.attachment]: { values: [], logicalOperation: 'OR' },
    [IncidentSearchType.status]: { values: [], logicalOperation: 'OR' },
    [IncidentSearchType.creator]: { values: [], logicalOperation: 'OR' },
  };

  // get selected item for edit mode
  const { selectedItem, itemType } = props.location.state || {};
  let defaultState = {
    selectedItem: undefined,
    itemType,
    searchType: IncidentSearchType.incidentNumber,
    searchResult: [],
    criteria: defaultCriteria,
    columns: getDefaultSearchResultColumns(),
  };

  if (selectedItem) {
    let selectedSearchType = IncidentSearchType.incidentNumber;
    if (selectedItem.criteria.dateReportedCriteria) {
      selectedSearchType = IncidentSearchType.dateReported;
    }
    if (selectedItem.criteria.dateOccurredCriteria) {
      selectedSearchType = IncidentSearchType.dateOccurred;
    }

    defaultState = {
      ...defaultState,
      selectedItem,
      criteria: { ...defaultCriteria, ...selectedItem.criteria },
      columns: selectedItem.columns || getDefaultSearchResultColumns(),
      searchType: selectedSearchType,
      incidentNumber: selectedItem.criteria.incidentNumber,
    };
  }

  const [state, setState] = React.useState(defaultState);
  const [
    datePeriodRequiredDlgOpen,
    setDatePeriodRequiredDlgOpen,
  ] = React.useState(false);
  const { isSmaller, isMobile } = useMediaInfo({ minBreakPoint: 'sm' });

  const { searchType, criteria } = state;

  const searchByIncidentNumber =
    searchType === IncidentSearchType.incidentNumber;
  const searchByDateReported = searchType === IncidentSearchType.dateReported;
  const searchByDateOccurred = searchType === IncidentSearchType.dateOccurred;
  const searchByClass = searchType === IncidentSearchType.class;
  const searchBySeverity = searchType === IncidentSearchType.severity;
  const searchByPerson = searchType === IncidentSearchType.person;
  const searchByCompany = searchType === IncidentSearchType.company;
  const searchByItem = searchType === IncidentSearchType.item;
  const searchByVehicle = searchType === IncidentSearchType.vehicle;
  const searchByLocation = searchType === IncidentSearchType.location;
  const searchByAddress = searchType === IncidentSearchType.address;
  const searchByAttachment = searchType === IncidentSearchType.attachment;
  const searchByStatus = searchType === IncidentSearchType.status;
  const searchByCreator = searchType === IncidentSearchType.creator;

  const convertLookupChildrenList = (type, options, lookups) => {
    const idListName =
      type === IncidentSearchType.class ? 'classIdList' : 'locationIdList';
    return options.map(option => {
      const ids = [];
      const { id, includeDescendant, lookupId } = option;
      ids.push(lookupId);
      includeDescendant &&
        ids.push(...getDescendantLookupIds(lookups, lookupId));
      return { [idListName]: ids, id, includeDescendant, lookupId };
    });
  };

  const getCriteriaNameHandlers = {
    [IncidentSearchType.dateReported]: getDateCriteriaName(
      props.companySettings.dateFormat,
    ),
    [IncidentSearchType.dateOccurred]: getDateCriteriaName(
      props.companySettings.dateFormat,
    ),
    [IncidentSearchType.person]: option => {
      const {
        firstName,
        lastName,
        middleName,
        hasPerson,
        isEmployee,
        relations,
      } = option;
      let personName = getPersonName({ firstName, lastName, middleName });

      if (hasPerson) {
        return 'has person';
      }

      if (isEmployee) {
        personName = `${personName} - Employee`;
      }

      if (relations) {
        personName = `${personName} - ${relations.join(', ')}`;
      }
      return personName;
    },
    [IncidentSearchType.company]: option => {
      const { name, hasCompany, isOwner, relations } = option;

      if (hasCompany) {
        return 'has legal entity';
      }

      let companyName = name;

      if (isOwner) {
        companyName = `${name} - Owner`;
      }

      if (relations) {
        companyName = `${companyName} - ${relations.join(', ')}`;
      }

      return companyName;
    },
    [IncidentSearchType.item]: option => {
      const { name, brand, model, color, serialNumber, hasItem } = option;

      if (hasItem) {
        return 'has item';
      }

      const modelName = `${brand || ''} ${model || ''} ${color || ''}`.trim();

      const itemName = `${name || ''} ${modelName || ''} ${serialNumber ||
        ''}`.trim();

      if (itemName) {
        return itemName;
      }

      return 'Unknown';
    },
    [IncidentSearchType.vehicle]: option => {
      const {
        licensePlate,
        stateProvince,
        maker,
        model,
        color,
        hasVehicle,
      } = option;

      if (hasVehicle) {
        return 'has vehicle';
      }

      let vehicleName = '';
      const modelName = `${maker || ''} ${model || ''} ${color || ''}`.trim();
      const number = `${licensePlate || ''} ${stateProvince || ''}`.trim();

      if (modelName && number) {
        vehicleName = `${modelName} (${number})`.trim();
      } else if (modelName) {
        vehicleName = modelName;
      } else if (number) {
        vehicleName = number;
      }

      if (vehicleName) {
        return vehicleName;
      }

      return 'Unknown';
    },
    [IncidentSearchType.address]: option => {
      const {
        unit,
        address,
        street,
        countryCode,
        stateProvinceCode,
        cityName,
      } = option;
      return [unit, address, street, countryCode, stateProvinceCode, cityName]
        .filter(Boolean)
        .join(', ');
    },
    [IncidentSearchType.attachment]: option =>
      option.hasAttachment ? 'has attachment' : option.name,
    [IncidentSearchType.status]: option =>
      translateConfig.enums.incidentStatus[option.status],
    [IncidentSearchType.creator]: getPersonName,
    [IncidentSearchType.severity]: option => option.lookupValue,
  };

  const getCriteria = () => {
    if (searchByIncidentNumber) {
      const { incidentNumber } = state;
      return incidentNumber ? { incidentNumber } : null;
    }

    const searchCriteria = {};
    Object.keys(criteria).forEach(type => {
      const item = criteria[type];
      if (item.values.length) {
        if (
          [IncidentSearchType.class, IncidentSearchType.location].includes(type)
        ) {
          const lookups =
            props.lookupDataSet[
              type === IncidentSearchType.class
                ? LookupTypeKey.incidentClass
                : LookupTypeKey.location
            ];
          const values = convertLookupChildrenList(type, item.values, lookups);
          Object.assign(searchCriteria, {
            [type]: {
              values,
              logicalOperation: item.logicalOperation,
            },
          });
        } else if (
          [
            IncidentSearchType.dateOccurred,
            IncidentSearchType.dateReported,
          ].includes(type)
        ) {
          item.values.forEach(value => {
            if (value.periodType === DateSearchPeriodType.period) {
              value.fromDate = new Date(value.fromDate);
              value.toDate = new Date(value.toDate);
            }
          });
          Object.assign(searchCriteria, { [type]: item });
        } else {
          Object.assign(searchCriteria, { [type]: item });
        }
      }
    });
    return searchCriteria;
  };

  const handleSearchTypeChange = event => {
    if (state.searchType === event.target.value) {
      setState({ ...state, searchType: event.target.value });
    } else {
      setState({
        ...state,
        searchType: event.target.value,
        searchResult: [],
      });
    }
  };

  const inputHandleChange = event => {
    setState({ ...state, [event.target.name]: event.target.value });
  };
  const handleSearch = _.debounce(dateOccurredCriteria => {
    const searchCriteria = getCriteria();
    if (_.isEmpty(searchCriteria)) {
      props.enqueueError('Please add a search criteria.');
      return;
    }

    if (dateOccurredCriteria) {
      setState({
        ...state,
        criteria: Object.assign({}, state.criteria, {
          dateOccurredCriteria,
        }),
      });
      searchCriteria.dateOccurredCriteria = dateOccurredCriteria;
      setDatePeriodRequiredDlgOpen(false);
    } else if (
      !searchCriteria.incidentNumber &&
      !searchCriteria.dateOccurredCriteria &&
      !searchCriteria.dateReportedCriteria
    ) {
      setDatePeriodRequiredDlgOpen(true);
      return;
    }

    props.searchIncidents(searchCriteria);
  }, 200);

  const addSearchCriteria = type => option => {
    const nextState = produce(state, draft => {
      const options = draft.criteria[type].values;
      options.push(option);
    });
    setState(nextState);
  };

  const deleteSearchCriteria = type => id => {
    const nextState = produce(state, draft => {
      draft.criteria[type].values = state.criteria[type].values.filter(
        x => x.id !== id,
      );
    });
    setState(nextState);
  };

  const changeCriteriaOperator = type => operator => {
    const nextState = produce(state, draft => {
      const item = draft.criteria[type];
      item.logicalOperation = operator.toUpperCase();
    });
    setState(nextState);
  };

  const clearSearchCriteria = () => {
    setState({
      ...state,
      criteria: defaultCriteria,
    });
  };

  const handleCloseSaveCustom = () => {
    props.closeCustom();
  };

  const handleSaveCustomDashboard = _.debounce(model => {
    if (props.openSave) {
      // save
      const searchCriteria = getCriteria();
      if (_.isEmpty(searchCriteria)) return;
      model.criteria = searchCriteria;
      model.columns = state.columns;
      if (props.saveAction === 'create') {
        props.saveCustom(model);
      } else {
        // update SelectedItem
        props.saveCustom(Object.assign({}, model, { id: selectedItem.id }));
      }
    } else {
      // open dialog
      props.openCustom({ saveTarget: 'Dashboard', saveAction: 'create' });
    }
  });

  const handleSaveCustomReport = _.debounce(model => {
    if (props.openSave) {
      // save
      const searchCriteria = getCriteria();
      if (_.isEmpty(searchCriteria)) return;
      model.criteria = searchCriteria;
      model.columns = state.columns;
      if (props.saveAction === 'create') {
        props.saveCustom(model);
      } else {
        // update SelectedItem
        props.saveCustom(Object.assign({}, model, { id: selectedItem.id }));
      }
    } else {
      // open dialog
      props.openCustom({ saveTarget: 'Report', saveAction: 'create' });
    }
  });

  const handleUpdateCustom = _.debounce(model => {
    const saveTarget =
      state.itemType === CustomReportTypeEnum.dashboardSearch
        ? 'Custom'
        : 'Report';
    // open dialog
    props.openCustom({ saveTarget, saveAction: 'update' });
  });

  const handleColumnsChange = columns => {
    setState({ ...state, columns });
  };

  React.useEffect(() => {
    props.getInitData();
    return () => {
      props.resetSearch();
    };
  }, []);

  React.useEffect(() => {
    if (props.loaded) {
      setState({ ...state, searchResult: props.incidentList });
    }
  }, [props.loaded]);

  const title = (
    <div className={classes.labelWithHelpIcon}>
      <SectionHeader
        title="Search"
        subtitle={state.selectedItem ? state.selectedItem.name : ''}
      />
      <HelpButton message="Search for incident records in the Incident Xpress database." />
    </div>
  );

  const onSearchOptionError = message => {
    props.enqueueError(message);
  };

  return (
    <DefaultLayout title={title}>
      <Grid container justify="center">
        <Grid
          spacing={0}
          alignItems="center"
          justify="center"
          container
          className={classes.grid}
        >
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Typography variant="h6" className={classes.labelWithHelpIcon}>
                Search Type
                <HelpButton message="Include one or more types as applicable to your Search." />
              </Typography>
              {isSmaller ? (
                <Box display="flex" flexDirection="column">
                  <Select
                    value={state.searchType}
                    onChange={handleSearchTypeChange}
                    name="searchType"
                    className={classes.formControl}
                  >
                    <MenuItem value={IncidentSearchType.incidentNumber}>
                      By incident #
                    </MenuItem>
                    <MenuItem value={IncidentSearchType.dateReported}>
                      By date reported
                    </MenuItem>
                    <MenuItem value={IncidentSearchType.dateOccurred}>
                      By date occurred
                    </MenuItem>
                    <MenuItem value={IncidentSearchType.class}>
                      By class
                    </MenuItem>
                    <MenuItem value={IncidentSearchType.person}>
                      By person
                    </MenuItem>
                    <MenuItem value={IncidentSearchType.company}>
                      By legal entity
                    </MenuItem>
                    <MenuItem value={IncidentSearchType.vehicle}>
                      By vehicle
                    </MenuItem>
                    <MenuItem value={IncidentSearchType.item}>By item</MenuItem>
                    <MenuItem value={IncidentSearchType.attachment}>
                      By attachment
                    </MenuItem>
                    <MenuItem value={IncidentSearchType.location}>
                      By location
                    </MenuItem>
                    <MenuItem value={IncidentSearchType.address}>
                      By address
                    </MenuItem>
                    <MenuItem value={IncidentSearchType.status}>
                      By status
                    </MenuItem>
                    <MenuItem value={IncidentSearchType.creator}>
                      By reporter
                    </MenuItem>
                  </Select>
                </Box>
              ) : (
                <RadioGroup
                  aria-label="Search Type"
                  name="searchType"
                  className={classes.group}
                  value={state.searchType}
                  onChange={handleSearchTypeChange}
                >
                  <FormControlLabel
                    value={IncidentSearchType.incidentNumber}
                    control={<Radio />}
                    label="By incident #"
                  />
                  <FormControlLabel
                    value={IncidentSearchType.dateReported}
                    control={<Radio />}
                    label="By date reported"
                  />
                  <FormControlLabel
                    value={IncidentSearchType.dateOccurred}
                    control={<Radio />}
                    label="By date occurred"
                  />
                  <FormControlLabel
                    value={IncidentSearchType.class}
                    control={<Radio />}
                    label="By class"
                  />
                  <FormControlLabel
                    value={IncidentSearchType.severity}
                    control={<Radio />}
                    label="By severity"
                  />
                  <FormControlLabel
                    value={IncidentSearchType.person}
                    control={<Radio />}
                    label="By person"
                  />
                  <FormControlLabel
                    value={IncidentSearchType.company}
                    control={<Radio />}
                    label="By legal entity"
                  />
                  <FormControlLabel
                    value={IncidentSearchType.vehicle}
                    control={<Radio />}
                    label="By vehicle"
                  />
                  <FormControlLabel
                    value={IncidentSearchType.item}
                    control={<Radio />}
                    label="By item"
                  />
                  <FormControlLabel
                    value={IncidentSearchType.attachment}
                    control={<Radio />}
                    label="By attachment"
                  />
                  <FormControlLabel
                    value={IncidentSearchType.location}
                    control={<Radio />}
                    label="By location"
                  />
                  <FormControlLabel
                    value={IncidentSearchType.address}
                    control={<Radio />}
                    label="By address"
                  />
                  <FormControlLabel
                    value={IncidentSearchType.status}
                    control={<Radio />}
                    label="By status"
                  />
                  <FormControlLabel
                    value={IncidentSearchType.creator}
                    control={<Radio />}
                    label="By user"
                  />
                </RadioGroup>
              )}
            </Paper>
            <Paper className={classes.paper}>
              <div className={classes.container}>
                {searchByIncidentNumber && (
                  <React.Fragment>
                    <div className={classes.searchFields}>
                      <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="incidentNumberCriteria">
                          Incident Number
                        </InputLabel>
                        <Input
                          id="incidentNumberCriteria"
                          name={IncidentSearchType.incidentNumber}
                          autoFocus={searchByIncidentNumber}
                          value={state.incidentNumber}
                          onChange={inputHandleChange}
                        />
                      </FormControl>
                    </div>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.searchButton}
                      onClick={() => handleSearch()}
                    >
                      Search
                    </Button>
                  </React.Fragment>
                )}
                {searchByDateOccurred && (
                  <div className={classes.container}>
                    <DateSearchTypeOption
                      title="Date occurred"
                      onSearchCriteriaAdded={addSearchCriteria(
                        IncidentSearchType.dateOccurred,
                      )}
                      dateFormat={props.companySettings.dateFormat}
                    />
                  </div>
                )}
                {searchByDateReported && (
                  <div className={classes.container}>
                    <DateSearchTypeOption
                      title="Date reported"
                      onSearchCriteriaAdded={addSearchCriteria(
                        IncidentSearchType.dateReported,
                      )}
                      dateFormat={props.companySettings.dateFormat}
                    />
                  </div>
                )}
                {searchByClass && (
                  <div className={classes.container}>
                    <LookupMultipleSearchOption
                      onSearchCriteriaAdded={addSearchCriteria(
                        IncidentSearchType.class,
                      )}
                      title="Incident Class"
                      lookupType={LookupTypeKey.incidentClass}
                    />
                  </div>
                )}
                {searchBySeverity && (
                  <div className={classes.container}>
                    <LookupSingleSearchOption
                      onSearchCriteriaAdded={addSearchCriteria(
                        IncidentSearchType.severity,
                      )}
                      lookups={props.lookupDataSet[LookupTypeKey.severityType]}
                      title="Severity"
                      name="severityId"
                    />
                  </div>
                )}
                {searchByPerson && (
                  <div className={classes.container}>
                    <PersonSearchOption
                      onSearchCriteriaAdded={addSearchCriteria(
                        IncidentSearchType.person,
                      )}
                      onError={message => {
                        onSearchOptionError(message);
                      }}
                    />
                  </div>
                )}
                {searchByCompany && (
                  <div className={classes.container}>
                    <CompanySearchOption
                      onSearchCriteriaAdded={addSearchCriteria(
                        IncidentSearchType.company,
                      )}
                      ownerCompanyName={
                        props.companySettings &&
                        props.companySettings.companyName
                      }
                      onError={message => {
                        onSearchOptionError(message);
                      }}
                    />
                  </div>
                )}
                {searchByVehicle && (
                  <div className={classes.container}>
                    <VehicleSearchOption
                      onSearchCriteriaAdded={addSearchCriteria(
                        IncidentSearchType.vehicle,
                      )}
                    />
                  </div>
                )}
                {searchByItem && (
                  <div className={classes.container}>
                    <ItemSearchOption
                      onSearchCriteriaAdded={addSearchCriteria(
                        IncidentSearchType.item,
                      )}
                    />
                  </div>
                )}
                {searchByLocation && (
                  <div className={classes.container}>
                    <LookupMultipleSearchOption
                      onSearchCriteriaAdded={addSearchCriteria(
                        IncidentSearchType.location,
                      )}
                      title="Location"
                      lookupType={LookupTypeKey.location}
                    />
                  </div>
                )}
                {searchByAddress && (
                  <div className={classes.container}>
                    <AddressSearchOption
                      onSearchCriteriaAdded={addSearchCriteria(
                        IncidentSearchType.address,
                      )}
                    />
                  </div>
                )}
                {searchByAttachment && (
                  <div className={classes.container}>
                    <AttachmentSearchOption
                      onSearchCriteriaAdded={addSearchCriteria(
                        IncidentSearchType.attachment,
                      )}
                    />
                  </div>
                )}
                {searchByStatus && (
                  <div className={classes.container}>
                    <StatusSearchOption
                      onSearchCriteriaAdded={addSearchCriteria(
                        IncidentSearchType.status,
                      )}
                    />
                  </div>
                )}
                {searchByCreator && (
                  <div className={classes.container}>
                    <CreatorSearchOption
                      onSearchCriteriaAdded={addSearchCriteria(
                        IncidentSearchType.creator,
                      )}
                    />
                  </div>
                )}
              </div>
            </Paper>
            <Paper className={classes.paper} hidden={searchByIncidentNumber}>
              <div className={classes.searchCriteriaTitle}>
                <Typography variant="h6" className={classes.labelWithHelpIcon}>
                  Search Criteria
                  <HelpButton message="Set one or more of each Search Type to refine the search results." />
                </Typography>
                <Button
                  variant="contained"
                  color="default"
                  className={classes.button}
                  onClick={clearSearchCriteria}
                >
                  Clear
                </Button>
              </div>
              <BaseSearchCriteria
                title="Date occurred"
                options={criteria[IncidentSearchType.dateOccurred].values}
                getName={
                  getCriteriaNameHandlers[IncidentSearchType.dateOccurred]
                }
                onDelete={deleteSearchCriteria(IncidentSearchType.dateOccurred)}
                onChangeOperator={changeCriteriaOperator(
                  IncidentSearchType.dateOccurred,
                )}
                onClick={() => {
                  setState({
                    ...state,
                    searchType: IncidentSearchType.dateOccurred,
                  });
                }}
              />
              <BaseSearchCriteria
                title="Date reported"
                options={criteria[IncidentSearchType.dateReported].values}
                getName={
                  getCriteriaNameHandlers[IncidentSearchType.dateReported]
                }
                onDelete={deleteSearchCriteria(IncidentSearchType.dateReported)}
                onChangeOperator={changeCriteriaOperator(
                  IncidentSearchType.dateReported,
                )}
                onClick={() => {
                  setState({
                    ...state,
                    searchType: IncidentSearchType.dateReported,
                  });
                }}
              />
              <LookupMultipleSearchCriteria
                options={criteria[IncidentSearchType.class].values}
                onDelete={deleteSearchCriteria(IncidentSearchType.class)}
                title="Class"
                lookupType={LookupTypeKey.incidentClass}
                onClick={() => {
                  setState({
                    ...state,
                    searchType: IncidentSearchType.class,
                  });
                }}
              />
              <BaseSearchCriteria
                title="Severity"
                options={criteria[IncidentSearchType.severity].values}
                getName={getCriteriaNameHandlers[IncidentSearchType.severity]}
                onDelete={deleteSearchCriteria(IncidentSearchType.severity)}
                onChangeOperator={changeCriteriaOperator(
                  IncidentSearchType.severity,
                )}
                onClick={() => {
                  setState({
                    ...state,
                    searchType: IncidentSearchType.severity,
                  });
                }}
              />
              <BaseSearchCriteria
                title="Person"
                options={criteria[IncidentSearchType.person].values}
                getName={getCriteriaNameHandlers[IncidentSearchType.person]}
                onDelete={deleteSearchCriteria(IncidentSearchType.person)}
                onChangeOperator={changeCriteriaOperator(
                  IncidentSearchType.person,
                )}
                onClick={() => {
                  setState({
                    ...state,
                    searchType: IncidentSearchType.person,
                  });
                }}
              />
              <BaseSearchCriteria
                title="Legal entity"
                options={criteria[IncidentSearchType.company].values}
                getName={getCriteriaNameHandlers[IncidentSearchType.company]}
                onDelete={deleteSearchCriteria(IncidentSearchType.company)}
                onChangeOperator={changeCriteriaOperator(
                  IncidentSearchType.company,
                )}
                onClick={() => {
                  setState({
                    ...state,
                    searchType: IncidentSearchType.company,
                  });
                }}
              />
              <BaseSearchCriteria
                title="Vehicle"
                options={criteria[IncidentSearchType.vehicle].values}
                getName={getCriteriaNameHandlers[IncidentSearchType.vehicle]}
                onDelete={deleteSearchCriteria(IncidentSearchType.vehicle)}
                onChangeOperator={changeCriteriaOperator(
                  IncidentSearchType.vehicle,
                )}
                onClick={() => {
                  setState({
                    ...state,
                    searchType: IncidentSearchType.vehicle,
                  });
                }}
              />
              <BaseSearchCriteria
                title="Item"
                options={criteria[IncidentSearchType.item].values}
                getName={getCriteriaNameHandlers[IncidentSearchType.item]}
                onDelete={deleteSearchCriteria(IncidentSearchType.item)}
                onChangeOperator={changeCriteriaOperator(
                  IncidentSearchType.item,
                )}
                onClick={() => {
                  setState({
                    ...state,
                    searchType: IncidentSearchType.item,
                  });
                }}
              />
              <LookupMultipleSearchCriteria
                options={criteria[IncidentSearchType.location].values}
                onDelete={deleteSearchCriteria(IncidentSearchType.location)}
                title="Location"
                lookupType={LookupTypeKey.location}
                onClick={() => {
                  setState({
                    ...state,
                    searchType: IncidentSearchType.location,
                  });
                }}
              />
              <BaseSearchCriteria
                title="Address"
                options={criteria[IncidentSearchType.address].values}
                getName={getCriteriaNameHandlers[IncidentSearchType.address]}
                onDelete={deleteSearchCriteria(IncidentSearchType.address)}
                onChangeOperator={changeCriteriaOperator(
                  IncidentSearchType.address,
                )}
                onClick={() => {
                  setState({
                    ...state,
                    searchType: IncidentSearchType.address,
                  });
                }}
              />
              <BaseSearchCriteria
                title="Attachment"
                options={criteria[IncidentSearchType.attachment].values}
                getName={getCriteriaNameHandlers[IncidentSearchType.attachment]}
                onDelete={deleteSearchCriteria(IncidentSearchType.attachment)}
                onChangeOperator={changeCriteriaOperator(
                  IncidentSearchType.attachment,
                )}
                onClick={() => {
                  setState({
                    ...state,
                    searchType: IncidentSearchType.attachment,
                  });
                }}
              />
              <BaseSearchCriteria
                title="Status"
                options={criteria[IncidentSearchType.status].values}
                getName={getCriteriaNameHandlers[IncidentSearchType.status]}
                onDelete={deleteSearchCriteria(IncidentSearchType.status)}
                onChangeOperator={changeCriteriaOperator(
                  IncidentSearchType.status,
                )}
                onClick={() => {
                  setState({
                    ...state,
                    searchType: IncidentSearchType.status,
                  });
                }}
              />
              <BaseSearchCriteria
                title="Entered by"
                options={criteria[IncidentSearchType.creator].values}
                getName={getCriteriaNameHandlers[IncidentSearchType.creator]}
                onDelete={deleteSearchCriteria(IncidentSearchType.creator)}
                onChangeOperator={changeCriteriaOperator(
                  IncidentSearchType.creator,
                )}
                onClick={() => {
                  setState({
                    ...state,
                    searchType: IncidentSearchType.creator,
                  });
                }}
              />
              <Button
                variant="contained"
                color="primary"
                className={classes.searchButton}
                onClick={() => handleSearch()}
              >
                Search
              </Button>
            </Paper>

            {props.loaded && (
              <SearchResult
                incidents={state.searchResult}
                updateEnabled={!!state.selectedItem}
                onSaveToDashboard={handleSaveCustomDashboard}
                onSaveToReport={handleSaveCustomReport}
                onUpdate={handleUpdateCustom}
                searchResultColumns={state.columns}
                onColumnsChange={handleColumnsChange}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      {props.openSave && (
        <SaveCustomSearchDialog
          open={props.openSave}
          onClose={handleCloseSaveCustom}
          onSave={handleSaveCustomDashboard}
          name={state.selectedItem && state.selectedItem.name}
          description={
            state.selectedItem && state.selectedItem.description
              ? state.selectedItem.description
              : ''
          }
        />
      )}
      <AlertDialog
        title="Date Period Required"
        message={`Date period doesn't exist. Search for the last 30 days?`}
        open={datePeriodRequiredDlgOpen}
        onClose={() => {
          setDatePeriodRequiredDlgOpen(false);
        }}
        onCancel={() => {
          setDatePeriodRequiredDlgOpen(false);
        }}
        onOk={() => {
          handleSearch({
            values: [
              {
                periodType: 'PERIOD',
                fromDate: moment()
                  .add(-30, 'day')
                  .toDate(),
                toDate: moment().toDate(),
                months: [],
                dayOfWeeks: [],
              },
            ],
            logicalOperation: 'OR',
          });
        }}
      />
    </DefaultLayout>
  );
};

Search.propTypes = {
  loaded: PropTypes.bool.isRequired,
  openSave: PropTypes.bool.isRequired,
  lookupDataSet: LookupDataSetDef.isRequired,
  incidentList: PropTypes.arrayOf(IncidentDef).isRequired,
  searchIncidents: PropTypes.func.isRequired,
  resetSearch: PropTypes.func.isRequired,
  saveCustom: PropTypes.func.isRequired,
  openCustom: PropTypes.func.isRequired,
  closeCustom: PropTypes.func.isRequired,
  getInitData: PropTypes.func.isRequired,
  enqueueError: PropTypes.func.isRequired,
  location: PropTypes.object,
  saveAction: PropTypes.string.isRequired,
  companySettings: PropTypes.object,
};

const mapStateToProps = ({ common, search }) => ({
  companySettings: common.companySettings,
  lookupDataSet: common.lookupDataSet,
  incidentList: search.incidentList,
  loaded: search.loaded,
  openSave: search.openSave,
  saveAction: search.saveAction,
});

const mapDispatchToProps = {
  searchIncidents,
  resetSearch,
  saveCustom,
  openCustom,
  closeCustom,
  getInitData,
  enqueueError,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Search));
